import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Loader from './components/layouts/loader';

if (window.location.pathname.split("/")[1] == "single-user-match-chat") {
  console.log(window.location.pathname);
  var AppAuth = React.lazy(() => import("./AppAuth"));
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <AppAuth user_id={window.location.pathname.split("/")[2]} />
    </Suspense>,
    document.getElementById("root")
  );
} else {
  var App = React.lazy(() => import("./App"));
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <App />
    </Suspense>,
    document.getElementById("root")
  );
}
